import events from 'jslib/custom-events';

class FormValidator {
    constructor(element, options = {}) {
        this.defaults = {
            fieldWrapperClass: 'form-group',
            fieldErrorClass: 'is-invalid',
            messageErrorClass: 'invalid-feedback',
            wrapperErrorClass: 'has-error',
            patterns: {
                // eslint-disable-next-line no-control-regex, no-regex-spaces, no-useless-escape
                email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                url: /^(?:(?:https?|HTTPS?|ftp|FTP):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[a-zA-Z\u00a1-\uffff0-9]-*)*[a-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[a-zA-Z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/,
                number: /^(?:[-+]?[0-9]*[.,]?[0-9]+)$/,
                color: /^#?([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/,
                date: /(?:19|20)[0-9]{2}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-9])|(?:(?!02)(?:0[1-9]|1[0-2])-(?:30))|(?:(?:0[13578]|1[02])-31))/,
                time: /^(?:(0[0-9]|1[0-9]|2[0-3])(:[0-5][0-9]))$/,
                month: /^(?:(?:19|20)[0-9]{2}-(?:(?:0[1-9]|1[0-2])))$/,
            },
            messages: {
                required: {
                    checkbox: 'This field is required',
                    radio: 'Please select a value',
                    select: 'Please select a value',
                    'select-multiple': 'Please select at least one value',
                    default: 'Please complete this field',
                },
                pattern: {
                    email: 'Please enter a valid email address',
                    url: 'Please enter a URL',
                    number: 'Please enter a number',
                    date: 'Please use the YYYY-MM-DD format',
                    time: 'Please use the 24-hour time format. Ex. 23:00',
                    month: 'Please use the YYYY-MM format',
                    default: 'Please match the requested format',
                },
                range: {
                    over: 'Please select a value that is no more than {max}',
                    under: 'Please select a value that is no less than {min}',
                },
                wrongLength: {
                    over: 'Please shorten this text to no more than {maxLength} characters. You are currently using {length} characters',
                    under: 'Please lengthen this text to {minLength} characters or more. You are currently using {length} characters',
                },
                fallback: 'There was an error with this field',
            },
        };

        this.options = Object.assign({}, this.defaults, options);

        if (element) {
            this.formEl = element;
            this.init();
        }
    }

    emitEvent(targetElement, eventName, eventDetails = {}) {
        if (typeof window.CustomEvent !== 'function' || !targetElement) return;

        const event = new CustomEvent(eventName, {
            bubbles: true,
            detail: eventDetails,
        });

        targetElement.dispatchEvent(event);
    }

    setEventListeners() {
        this.formEl.addEventListener('blur', this.onFieldBlur.bind(this), true);
        this.formEl.addEventListener('input', this.onFieldChange.bind(this));
        this.formEl.addEventListener('click', this.onFieldChange.bind(this));
        this.formEl.addEventListener('change', this.onFieldChange.bind(this));
        this.formEl.addEventListener('submit', this.onFormSubmit.bind(this));
    }

    onFieldBlur(event) {
        this.validateField(event.target);
    }

    onFieldChange(event) {
        if (!event.target.classList.contains(this.options.fieldErrorClass)) {
            return;
        }

        this.validateField(event.target);
    }

    onFormSubmit() {
        const errors = this.validateAllFields(this.formEl);

        if (errors.length) {
            errors[0].focus();

            this.emitEvent(this.formEl, events.formValidator.invalid, { errors });

            return;
        }

        this.emitEvent(this.formEl, events.formValidator.valid);
    }

    addNoValidate(element) {
        if (element) {
            element.setAttribute('novalidate', true);
        }
    }

    removeNoValidate(element) {
        if (element) {
            element.removeAttribute('novalidate', true);
        }
    }

    validateRequired(field) {
        if (!field.hasAttribute('required')) {
            return false;
        }

        if (field.type === 'checkbox') {
            return !field.checked;
        }

        let length = field.value.length;

        if (field.type === 'radio') {
            length = Array.prototype.filter.call(
                field.form.querySelectorAll(`[name="${field.name}"]`),
                (radioEl) => radioEl.checked
            ).length;
        }

        return length < 1;
    }

    validatePattern(field) {
        let pattern = field.getAttribute('pattern');

        pattern = pattern ? new RegExp(pattern) : this.options.patterns[field.type];

        if (!pattern || !field.value || !field.value.length) {
            return false;
        }

        return !pattern.test(field.value);
    }

    validateRange(field) {
        let value = field.value;
        let max = field.getAttribute('max');
        let min = field.getAttribute('min');

        if (field.type === 'number') {
            const step = field.getAttribute('step');

            if (step && step.includes('.')) {
                value = parseFloat(value);
                min = parseFloat(min);
                max = parseFloat(max);
            } else {
                value = parseInt(value);
                min = parseInt(min);
                max = parseInt(max);
            }
        }

        if (!value || !field.value.length) {
            return false;
        }

        if (max && value > max) {
            return 'over';
        }

        if (min && value < min) {
            return 'under';
        }

        return false;
    }

    validateLength(field) {
        if (!field.value || !field.value.length) {
            return false;
        }

        const max = parseInt(field.getAttribute('maxlength'));
        const min = parseInt(field.getAttribute('minlength'));

        if (max && field.value.length > max) {
            return 'over';
        }

        if (min && field.value.length < min) {
            return 'under';
        }

        return false;
    }

    runValidations(field) {
        return {
            required: this.validateRequired(field),
            pattern: this.validatePattern(field),
            range: this.validateRange(field),
            wrongLength: this.validateLength(field),
        };
    }

    hasErrors(errors) {
        for (const type in errors) {
            if (errors[type]) {
                return true;
            }
        }

        return false;
    }

    getErrors(field) {
        const errors = this.runValidations(field);

        return {
            valid: !this.hasErrors(errors),
            errors: errors,
        };
    }

    getErrorId(field) {
        const errorId = field.type === 'radio' ? field.name : field.id;

        return `${errorId}Feedback`;
    }

    createErrorMessageElement(field) {
        const errorEl = document.createElement('div');

        errorEl.className = this.options.messageErrorClass;
        errorEl.id = this.getErrorId(field);

        if (field.type === 'checkbox' || field.type === 'radio' || field.multiple || field.closest('.input-group')) {
            field.closest(`.${this.options.fieldWrapperClass}`).appendChild(errorEl);
        } else {
            field.after(errorEl);
        }

        return errorEl;
    }

    getErrorMessageText(field, errors) {
        const messages = this.options.messages;

        if (errors.required) {
            return (
                field.getAttribute('data-validator-required-message') ||
                messages.required[field.type] ||
                messages.required.default
            );
        }

        if (errors.range) {
            return messages.range[errors.range]
                .replace('{max}', field.getAttribute('max'))
                .replace('{min}', field.getAttribute('min'))
                .replace('{length}', field.value.length);
        }

        if (errors.wrongLength) {
            return messages.wrongLength[errors.wrongLength]
                .replace('{maxLength}', field.getAttribute('maxlength'))
                .replace('{minLength}', field.getAttribute('minlength'))
                .replace('{length}', field.value.length);
        }

        if (errors.pattern) {
            return (
                field.getAttribute('data-validator-pattern-message') ||
                messages.pattern[field.type] ||
                messages.pattern.default
            );
        }

        return messages.fallback;
    }

    addErrorAttributes(field, errorId) {
        field.classList.add(this.options.fieldErrorClass);
        field.setAttribute('aria-describedby', errorId);
        field.setAttribute('aria-invalid', true);
        field.closest(`.${this.options.fieldWrapperClass}`).classList.add(this.options.wrapperErrorClass);
    }

    showErrorAttributes(field, errorId) {
        if (field.type === 'radio' && field.name) {
            this.formEl
                .querySelectorAll(`[name="${field.name}"]`)
                .forEach((radioEl) => this.addErrorAttributes(radioEl, errorId));
        }

        this.addErrorAttributes(field, errorId);
    }

    showError(field, errors) {
        const errorId = this.getErrorId(field);
        const message = this.getErrorMessageText(field, errors);
        let errorEl = document.getElementById(errorId);

        if (!errorEl) {
            errorEl = this.createErrorMessageElement(field);
        }

        errorEl.textContent = message;

        this.showErrorAttributes(field, errorId);
        this.emitEvent(field, events.formValidator.showError, { errors });
    }

    removeErrorAttributes(field) {
        field.classList.remove(this.options.fieldErrorClass);
        field.removeAttribute('aria-describedby');
        field.removeAttribute('aria-invalid');
        field.closest(`.${this.options.fieldWrapperClass}`).classList.remove(this.options.wrapperErrorClass);
    }

    removeFieldErrorAttributes(field) {
        if (field.type === 'radio' && field.name) {
            document
                .querySelectorAll(`[name="${field.name}"]`)
                .forEach((radioEl) => this.removeErrorAttributes(radioEl));

            return;
        }

        this.removeErrorAttributes(field);
    }

    removeError(field) {
        const errorId = this.getErrorId(field);
        const errorEl = document.getElementById(errorId);

        if (!errorEl) {
            return;
        }

        errorEl.remove();

        this.removeFieldErrorAttributes(field);
        this.emitEvent(field, events.formValidator.removeError);
    }

    removeAllErrors() {
        this.formEl
            .querySelectorAll('input:not([type=hidden]), select, textarea')
            .forEach((fieldEl) => this.removeError(fieldEl));
    }

    validateField(field) {
        if (
            field.disabled ||
            field.readOnly ||
            field.type === 'reset' ||
            field.type === 'submit' ||
            field.type === 'button'
        ) {
            return;
        }

        const isValid = this.getErrors(field);

        if (isValid.valid) {
            this.removeError(field);

            return;
        }

        this.showError(field, isValid.errors);

        return isValid;
    }

    validateAllFields() {
        return Array.prototype.filter.call(
            this.formEl.querySelectorAll('input:not([type=hidden]), select, textarea'),
            (fieldEl) => {
                const isValid = this.validateField(fieldEl);

                return isValid && !isValid.valid;
            }
        );
    }

    validateForm() {
        const invalidFields = this.validateAllFields();

        return !invalidFields.length;
    }

    init() {
        this.setEventListeners();
        this.addNoValidate();
    }
}

export default FormValidator;
