import { Toast } from 'bootstrap';

window.notifications = function () {
    return {
        duration: 4000,
        visible: false,
        type: 'notice',
        message: null,
        show: function (options) {
            if (options.type) {
                this.type = options.type;
            }

            if (options.message) {
                this.message = options.message;
            }

            this.visible = true;

            setTimeout(
                function () {
                    const toastElList = document.querySelectorAll('.toast');

                    if (toastElList) {
                        toastElList.forEach((toastEl) => {
                            const toast = new Toast(toastEl);
                            toast.show();
                        });
                    }
                }.bind(this),
                250
            );

            setTimeout(
                function () {
                    this.visible = false;
                }.bind(this),
                this.duration
            );
        },
    };
};

window.addNotification = function (type = 'notice', message = null) {
    if (type && message) {
        window.dispatchEvent(
            new CustomEvent('notification', {
                detail: {
                    type: type,
                    message: message,
                },
            })
        );
    }
};
