import Elemental from 'jslib/elemental/elemental';

Elemental.Components.ProductGallery = class ProductGallery extends Elemental.BaseComponent {
    constructor(element, options) {
        super(element, options);
    }

    setVars() {
        this.css = {
            classes: {
                active: 'active',
            },
            selectors: {
                carousel: '[data-ds-component="carousel"]',
                thumbButton: '.product-gallery__thumb',
            },
        };

        this.thumbButtonEls = this.element.querySelectorAll(this.css.selectors.thumbButton);
        this.carouselEl = this.element.querySelector(this.css.selectors.carousel);
    }

    setEventListeners() {
        this.thumbButtonEls.forEach((buttonEl) =>
            buttonEl.addEventListener('click', this.onThumbButtonClick.bind(this))
        );
        this.carouselEl.addEventListener('slideChange', this.onSlideChange.bind(this));
    }

    onSlideChange(event) {
        const index = event.detail.activeIndex;
        const thumbEl = this.element.querySelector(`${this.css.selectors.thumbButton}[data-index="${index}"]`);

        if (thumbEl && !thumbEl.classList.contains(this.css.classes.active)) {
            this.setThumbActive(thumbEl);
        }
    }

    onThumbButtonClick(event) {
        const index = event.currentTarget.dataset.index;

        if (index) {
            this.getCarouselComponent().then((carouselComponent) => {
                carouselComponent.carousel.slideTo(index);
                this.setThumbActive(event.currentTarget);
            });
        }
    }

    setThumbActive(thumbEl) {
        this.thumbButtonEls.forEach((buttonEl) => buttonEl.classList.remove(this.css.classes.active));
        thumbEl.classList.add(this.css.classes.active);
    }

    async getCarouselComponent() {
        return Elemental.getComponentById(this.carouselEl.dataset.elementalId);
    }

    render() {
        this.setVars();
        this.setEventListeners();
    }
};
