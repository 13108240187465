import '../scss/main.scss';

import 'core-js/stable';
import Alpine from 'alpinejs';
import Elemental from 'jslib/elemental/elemental';
import events from 'jslib/custom-events';
import { loadSvgSprite } from 'jslib/utils/utils';
import 'jslib/notifications';
import { Collapse, Dropdown, Tab, Toast, Offcanvas, Modal } from 'bootstrap';
import LazyLoad from 'vanilla-lazyload/dist/lazyload';

import '../../components/autocomplete/js/autocomplete';
import '../../components/basket/js/basket';
import '../../components/carousel/js/carousel';
import '../../components/form/js/form';
import '../../components/product-gallery/js/product-gallery';
import '../../components/decking-calculator/js/decking-calculator';
import '../../components/tooltip/js/tooltip';

Elemental.init({
    window: window,
    globalObject: DECKINGSUPPLIES,
});

loadSvgSprite(DECKINGSUPPLIES.assets.svg);

DECKINGSUPPLIES.lazyLoad = new LazyLoad({
    elements_selector: '.lazy-load',
    restore_on_error: true,
    callback_loaded: (element) => element.dispatchEvent(new CustomEvent(events.images.lazyLoaded)),
});

window.Alpine = Alpine;

Alpine.start();

window.addEventListener('CookiebotOnDecline', () => {
    // Find all images that were exited and force-reload them
    document.querySelectorAll('img.lazy-load.exited').forEach((imgEl) => {
        imgEl.classList.remove('exited', 'loaded', 'entered');
        imgEl.removeAttribute('data-ll-status');
    });

    DECKINGSUPPLIES.lazyLoad.update();
});

window.addEventListener('ga4_event', (event) => {
    const data = event.detail ?? null;

    if (data) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(data);
        console.log(data);
    }
});

window.dispatchEvent(
    new CustomEvent('ga4_event', {
        bubbles: true,
        detail: {
            event: 'page_view',
        },
    })
);
