import Elemental from 'jslib/elemental/elemental';
import { Tooltip as BootstrapTooltip } from 'bootstrap';

Elemental.Components.Tooltip = class Tooltip extends Elemental.BaseComponent {
    constructor(element, options) {
        super(element, options);
    }

    setVars() {
        this.initOnLoad = this.element.dataset.initOnLoad && this.element.dataset.initOnLoad === 'false' ? false : true;
    }

    init(options = {}) {
        if (this.initOnLoad) {
            this.bsTooltip = new BootstrapTooltip(this.element, options);
        }

        this.initOnLoad = true;
    }

    render() {
        this.setVars();
        this.init();
    }
};
