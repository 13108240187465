import Elemental from 'jslib/elemental/elemental';

Elemental.Components.Basket = class Basket extends Elemental.BaseComponent {
    constructor(element, options) {
        super(element, options);
    }

    setVars() {
        this.css = {
            selectors: {
                navBasketOffcanvas: 'navBasketOverlay',
            },
        };

        this.navBasketEl = document.getElementById(this.css.selectors.navBasketOffcanvas);
    }

    setEventListeners() {
        this.navBasketEl.addEventListener('show.bs.offcanvas', this.onShow.bind(this));
    }

    onShow(event) {
        this.globalObject.lazyLoad.update();
    }

    render() {
        this.setVars();
        this.setEventListeners();
    }
};
