import Elemental from 'jslib/elemental/elemental';
import FormValidator from 'jslib/form-validator/form-validator';
import events from 'jslib/custom-events';
import { getElementDimensions } from 'jslib/utils/utils';
import isObjectLike from 'lodash/isObjectLike';
import isString from 'lodash/isString';
import delay from 'lodash/delay';
import loadingTemplate from '../templates/loading';
import errorListTemplate from '../templates/error-list';

Elemental.Components.Form = class Form extends Elemental.BaseComponent {
    constructor(element, options) {
        super(element, options);
    }

    setVars() {
        this.css = {
            classes: {
                loading: 'loading',
                hidden: 'd-none',
            },
            selectors: {
                formGroup: '.form-group',
                formFieldsWrapper: '.form__fields',
                success: '.form__success',
            },
        };

        this.submitButtonEl = this.element.querySelector('button[type=submit]');

        this.ajaxSubmit = this.element.dataset.ajaxSubmit;
        this.successMessage = this.element.dataset.successText;
        this.successRedirect = this.element.dataset.successRedirect;
        this.successTextOverlay = this.element.dataset.successTextOverlay;
        this.showCloseButton = this.element.dataset.showCloseButton;
        this.submitButtonText = this.submitButtonEl ? this.submitButtonEl.innerHTML : 'Submit';
        this.showLoadingState = true;

        if (this.element.dataset.showLoadingState) {
            this.showLoadingState = JSON.parse(this.element.dataset.showLoadingState);
        }
    }

    setEventListeners() {
        this.element.addEventListener(events.forms.resetForm, this.resetForm.bind(this));
        this.element.addEventListener(events.forms.resetValidation, this.resetValidation.bind(this));
        this.element.addEventListener(events.forms.submitted, this.onSubmissionFinished.bind(this));
        this.element.addEventListener('submit', this.onSubmit.bind(this));
    }

    onSubmit(event) {
        const isFormValid = this.isFormValid();

        if (isFormValid) {
            this.routeFormToDestination(event);
        } else {
            event.preventDefault();
        }

        this.setSubmitButtonDisabled(isFormValid);
    }

    onFormSubmitCompleted(success, data) {
        if (data && isObjectLike(data) && !success) {
            let errors = data.errors || [];

            if (data.error) {
                errors = [data.error];
            }

            if (data.message) {
                errors.push(data.message);
            }

            if (isString(errors)) {
                errors = [errors];
            }

            // Ensure any old errors are removed before we add new ones
            this.resetValidation();

            this.setSubmitButtonDisabled(false);
            this.setSubmitButtonLoading(false);

            return;
        }

        if (success) {
            const message = this.successMessage || data.message || null;

            if (message) {
                this.showSuccessMessage(message);
            }

            this.setSubmitButtonLoading(false);
            this.setSubmitButtonDisabled(false);

            if (this.element.dataset.resetOnSuccess === 'true') {
                this.clearFormInputs();
            }

            this.resetValidation();
            this.emitEvent(this.element, events.forms.submitted, { data });
        }
    }

    onSubmissionFinished() {
        if (this.successRedirect && this.successRedirect.length) {
            delay(() => {
                if (this.successRedirect === window.location.href) {
                    window.location.reload();
                } else {
                    window.location.href = this.successRedirect;
                }
            }, 2000);
        }
    }

    isFormValid() {
        return this.validator.validateForm();
    }

    resetValidation() {
        const errorsEl = this.element.querySelector(this.css.selectors.errors);

        if (errorsEl) {
            errorsEl.remove();
        }

        this.validator.removeAllErrors();
    }

    routeFormToDestination(event) {
        if (this.ajaxSubmit) {
            event.preventDefault();

            this.submitForm().then((response) => {
                const success = response.status === 200;

                response.json().then(this.onFormSubmitCompleted.bind(this, success));
            });
        } else {
            this.setSubmitButtonLoading(true);
        }
    }

    setSubmitButtonDisabled(disabled) {
        if (!this.showLoadingState) {
            return;
        }

        if (this.submitButtonEl) {
            this.submitButtonEl.disabled = disabled;
        }
    }

    setSubmitButtonLoading(isLoading) {
        if (!this.showLoadingState) {
            return;
        }

        if (isLoading) {
            this.renderSubmitButtonLoadingState(this.element.dataset.loadingText);
        } else {
            this.renderSubmitButtonDefaultState(this.submitButtonText);
        }
    }

    renderErrorList(event, errors) {
        const errorData = {
            errors: errors,
        };
        const errorHtml = errorListTemplate(errorData);
        const errorHtmlEl = document.createRange().createContextualFragment(errorHtml);
        const errorsEl = this.element.querySelector(this.css.selectors.errors);

        if (errorsEl) {
            errorsEl.replaceWith(errorHtmlEl);
        } else {
            this.element.prepend(errorHtmlEl);
        }
    }

    renderSubmitButtonLoadingState(loadingText) {
        const loadingHtml = loadingTemplate({
            text: loadingText,
        });
        const buttonSize = getElementDimensions(this.submitButtonEl);

        // Fix button dimensions while in loading state
        this.submitButtonEl.style.width = `${buttonSize.width}px`;
        this.submitButtonEl.style.height = `${buttonSize.height}px`;
        this.submitButtonEl.innerHTML = loadingHtml;
        this.submitButtonEl.classList.add(this.css.classes.loading);
    }

    renderSubmitButtonDefaultState(buttonText) {
        this.submitButtonEl.innerHTML = buttonText;
        this.submitButtonEl.style.width = '';
        this.submitButtonEl.style.height = '';
        this.submitButtonEl.classList.remove(this.css.classes.loading);
    }

    getFormData() {
        const formData = new FormData(this.element);

        return formData;
    }

    async submitForm() {
        this.setSubmitButtonDisabled(true);
        this.setSubmitButtonLoading(true);

        return await fetch('#', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
            },
            body: this.getFormData(),
        });
    }

    resetForm() {
        this.clearFormInputs();
        this.resetValidation();
        this.setSubmitButtonDisabled(false);
        this.setSubmitButtonLoading(false);
    }

    clearFormInputs() {
        this.element.reset();

        this.element
            .querySelectorAll('select, textarea, input:not([type=hidden])')
            .forEach((formEl) => this.emitEvent(formEl, 'change'));
    }

    init() {
        this.validator = new FormValidator(this.element);
    }

    render() {
        this.setVars();
        this.setEventListeners();
        this.init();
    }
};
