/* eslint-disable new-cap */
function compile(item, components, html) {
    const highlight = html`<img src="${item.thumbnail}" width="35" height="35" alt="${item.title} image" class="me-3" />
    ${components.Highlight({
        hit: item,
        attribute: 'title',
    })}
    <span class="sku ms-2">${components.Highlight({
        hit: item,
        attribute: 'sku',
    })}</span>
    `;

    return highlight;
}

export default compile;
