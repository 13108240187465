const loadSvgSprite = (spriteUrl) => {
    fetch(spriteUrl, {
        method: 'GET',
    })
        .then((response) => response.text())
        .then((data) => {
            const div = document.createElement('div');

            div.innerHTML = data;
            div.classList.add('svg-sprite');
            document.body.insertBefore(div, document.body.childNodes[0]);
        });
};

const getElementDimensions = (element) => {
    const elStyle = getComputedStyle(element);
    const width = parseInt(elStyle.width.replace('px', ''), 10);
    const height = parseInt(elStyle.height.replace('px', ''), 10);
    const paddingWidth =
        parseInt(elStyle.paddingLeft.replace('px', ''), 10) + parseInt(elStyle.paddingRight.replace('px', ''), 10);
    const paddingHeight =
        parseInt(elStyle.paddingTop.replace('px', ''), 10) + parseInt(elStyle.paddingBottom.replace('px', ''), 10);

    return {
        width: width,
        height: height,
        innerWidth: width - paddingWidth,
        innerHeight: height - paddingHeight,
        paddingWidth: paddingWidth,
        paddingHeight: paddingHeight,
    };
};

export { loadSvgSprite, getElementDimensions };
