import Elemental from 'jslib/elemental/elemental';
import itemTemplate from '../templates/item';
import noResultsTemplate from '../templates/no-results';
import footerTemplate from '../templates/footer';
import algoliasearch from 'algoliasearch';
import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js';
import '@algolia/autocomplete-theme-classic';

Elemental.Components.Autocomplete = class Autocomplete extends Elemental.BaseComponent {
    constructor(element, options) {
        super(element, options);
    }

    setVars() {
        const elementCssClass = 'autocomplete';

        this.css = {
            classes: {
                form: `${elementCssClass}__form`,
                input: `${elementCssClass}__input form-control-lg`,
                inputWrapper: `${elementCssClass}__input-wrapper`,
                label: `${elementCssClass}__label`,
                list: `${elementCssClass}__results`,
                item: `${elementCssClass}__results__item`,
                inputWrapperPrefix: `${elementCssClass}__input-wrapper-prefix`,
                inputWrapperSuffix: `${elementCssClass}__input-wrapper-suffix`,
                loadingIndicator: `${elementCssClass}__loading`,
                submitButton: `${elementCssClass}__submit`,
                clearButton: `${elementCssClass}__clear`,
                panel: `${elementCssClass}__panel`,
                panelLayout: `${elementCssClass}__panel-layout`,
                source: `${elementCssClass}__source`,
                browseAll: 'browse-all',
            },
            selectors: {
                autocompleteContainer: '#navSearch',
            },
        };

        this.indices = this.element.dataset.indices.split(',');
        this.searchClient = algoliasearch(
            this.globalObject.algolia.applicationId,
            this.globalObject.algolia.searchApiKey
        );
    }

    onItemSelect({ itemUrl }) {
        const url = `${window.location.origin}${itemUrl}`;

        window.location.href = url;
    }

    renderResultItem({ item, components, html }) {
        return itemTemplate(item, components, html);
    }

    renderNoResults() {
        return noResultsTemplate();
    }

    renderFooter({ html }) {
        return footerTemplate(html);
    }

    getResultItemUrl({ item }) {
        return item.absoluteUri;
    }

    getAlgoliaSourceItems(indexName, query) {
        const hitsPerPage = 10;

        return getAlgoliaResults({
            searchClient: this.searchClient,
            queries: [
                {
                    indexName,
                    query,
                    params: {
                        hitsPerPage,
                    },
                },
            ],
        });
    }

    getAlgoliaSources({ query, setQuery, refresh }) {
        const sources = [];

        this.setQuery = setQuery;
        this.refresh = refresh;

        this.indices.forEach((index) => {
            sources.push({
                sourceId: index,
                getItems: this.getAlgoliaSourceItems.bind(this, index, query),
                getItemUrl: this.getResultItemUrl.bind(this),
                onSelect: this.onItemSelect.bind(this),
                templates: {
                    item: this.renderResultItem.bind(this),
                    noResults: this.renderNoResults.bind(this),
                    footer: this.renderFooter.bind(this),
                },
            });
        });

        return sources;
    }

    init() {
        this.autocomplete = autocomplete({
            container: this.css.selectors.autocompleteContainer,
            classNames: {
                form: this.css.classes.form,
                input: this.css.classes.input,
                inputWrapper: this.css.classes.inputWrapper,
                label: this.css.classes.label,
                list: this.css.classes.list,
                item: this.css.classes.item,
                inputWrapperPrefix: this.css.classes.inputWrapperPrefix,
                inputWrapperSuffix: this.css.classes.inputWrapperSuffix,
                loadingIndicator: this.css.classes.loadingIndicator,
                submitButton: this.css.classes.submitButton,
                clearButton: this.css.classes.clearButton,
                panel: this.css.classes.panel,
                panelLayout: this.css.classes.panelLayout,
                source: this.css.classes.source,
            },
            placeholder: 'Search for products',
            getSources: this.getAlgoliaSources.bind(this),
            enterKeyHint: 'search',
        });
    }

    render() {
        this.setVars();
        this.init();
    }
};
