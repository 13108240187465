function formatPrice(price) {
    return new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(price);
}

function getTotalCost(data, format = true) {
    let total = 0;

    data.forEach((productType) => {
        productType.forEach((product) => {
            total += parseFloat(product.product.price) * Math.round(product.count);
        });
    });

    if (format) {
        return formatPrice(total);
    }

    return total;
}

function getKlarnaTotalCost(data) {
    const total = getTotalCost(data, false);

    return Math.floor(total * 100);
}

function getListItem(item, index, productIndex) {
    const price = parseFloat(item.product.price);
    const quantity = Math.round(item.count);
    const subtotal = price * quantity;
    const id = `${index}${productIndex}`;

    return `<tr>
		<th scope="row">
			${item.product.name}
			${item.product.onSale ? '<span class="badge bg-danger ms-1">Sale</span>' : ''}
			<input type="hidden" name="purchasables[${id}][id]" value="${item.id}">
			<input type="hidden" name="purchasables[${id}][qty]" value="${quantity}">
			<input type="hidden" name="purchasables[${id}][options][fromCalculator]" value="true">
		</th>
	    <td class="text-end">${formatPrice(price)}</td>
		<td class="text-end">${quantity}</td>
		<td class="text-end">${formatPrice(subtotal)}</td>
	  </tr>
	`;
}

function getShoppingListItems(productType, index) {
    return productType.map((product, productIndex) => getListItem(product, index, productIndex)).join('');
}

function shoppingList(data) {
    return `<div class="border border-bottom-0 rounded-top mt-4 p4 p-lg-5" id="calculatorResults">
		<h2 class="h3">Calculated product list</h2>
		<p>All prices shown are inclusive of VAT.</p>
		<div class="table-responsive">
			<table class="table border mt-4 mb-0">
				<thead>
					<tr>
		    			<th scope="col" class="bg-light">Product</th>
		  	  			<th scope="col" class="bg-light text-end">Unit price</th>
		  				<th scope="col" class="bg-light text-end">Quantity</th>
		  				<th scope="col" class="bg-light text-end">Subtotal</th>
					</tr>
	  			</thead>
	  			<tbody>
				${data.map((productType, index) => getShoppingListItems(productType, index)).join('')}
				</tbody>
				<tfoot>
					<tr>
						<th scope="row" colspan="3" class="text-end bg-light">Total</th>
						<td class="text-end fw-bold bg-light">${getTotalCost(data)}</td>
					</tr>
				</tfoot>
			</table>
            <div class="mt-3 mt-lg-4">
			    <klarna-placement
				    data-key="credit-promotion-badge"
				    data-locale="en-GB"
				    data-purchase-amount="${getKlarnaTotalCost(data)}"
			    ></klarna-placement>
            </div>
		</div>
	</div>
	<div class="d-flex justify-content-center bg-secondary rounded-bottom px-4 px-lg-5 py-3">
		<button type="submit" class="btn btn-lg btn-primary">
			<svg class="icon icon--basket"><use xlink:href="#basket"></use></svg>
			Add all to basket
		</button>
	</div>`;
}

function compile(data) {
    return shoppingList(data);
}

export default compile;
