export default {
    carousel: {
        resetBreakpoints: 'ds:carousel:resetBreakpoints',
    },
    forms: {
        error: 'ds:forms:error',
        resetForm: 'ds:forms:resetForm',
        resetValidation: 'ds:forms:resetValidation',
        submitted: 'ds:forms:submitted',
    },
    formValidator: {
        valid: 'ds:formValidator:valid',
        invalid: 'ds:formValidator:invalid',
        showError: 'ds:formValidator:showError',
        removeError: 'ds:formValidator:removeError',
    },
    images: {
        lazyLoaded: 'ds:images:lazyLoaded',
    },
};
