import Elemental from 'jslib/elemental/elemental';
import events from 'jslib/custom-events';
import shoppingListTemplate from '../templates/shopping-list';

Elemental.Components.DeckingCalculator = class DeckingCalculator extends Elemental.BaseComponent {
    constructor(element, options) {
        super(element, options);
    }

    setVars() {
        const elementCssClass = 'decking-calculator';

        this.css = {
            selectors: {
                form: `.${elementCssClass}__form`,
                shoppingList: `.${elementCssClass}__shopping-list`,
                instructions: `.${elementCssClass}__instructions`,
                rangeRadios: `.${elementCssClass}__range .btn-check`,
                calculatorResults: 'calculatorResults',
            },
        };

        this.formEls = this.element.querySelectorAll(this.css.selectors.form);
        this.rangeRadioEls = this.element.querySelectorAll(this.css.selectors.rangeRadios);
    }

    setEventListeners() {
        this.formEls.forEach((formEl) =>
            formEl.addEventListener(events.forms.submitted, this.onFormSubmitCompleted.bind(this))
        );
        this.rangeRadioEls.forEach((formEl) => formEl.addEventListener('click', this.onRangeRadioClick.bind(this)));
    }

    onRangeRadioClick(event) {
        const colourWrapperEl = document.getElementById(event.target.value);

        if (colourWrapperEl) {
            const radioEl = colourWrapperEl.querySelector('input[type=radio]');

            if (radioEl) {
                radioEl.checked = true;
            }
        }
    }

    onFormSubmitCompleted(event) {
        const formEl = event.target;
        const data = event.detail.data;

        if (data && data.results && data.results) {
            this.renderShoppingList(formEl, data.results.shoppingLists);
            this.renderInstructions(formEl, data.results.instructions);
        } else {
            // TODO handle error
        }

        const calculatorResultsEl = document.getElementById(this.css.selectors.calculatorResults);

        if (calculatorResultsEl) {
            this.window.scrollTo({
                top: calculatorResultsEl.offsetTop,
                left: 0,
                behavior: 'smooth',
            });
        }
    }

    renderShoppingList(formEl, data) {
        const listHtml = shoppingListTemplate(data);
        const htmlEl = document.createRange().createContextualFragment(listHtml);
        const shoppingListContainerEl = formEl.parentNode.querySelector(this.css.selectors.shoppingList);

        if (shoppingListContainerEl) {
            shoppingListContainerEl.innerHTML = '';
            shoppingListContainerEl.appendChild(htmlEl);
        }

        this.trackEvent('decking_calculator');
    }

    renderInstructions(formEl, instructionsHtml) {
        const instructionsEl = document.createRange().createContextualFragment(instructionsHtml);
        const instructionsContainerEl = formEl.parentNode.querySelector(this.css.selectors.instructions);

        if (instructionsContainerEl) {
            instructionsContainerEl.innerHTML = '';
            instructionsContainerEl.appendChild(instructionsEl);
        }
    }

    trackEvent(eventName) {
        this.emitEvent(this.window, 'ga4_event', {
            event: eventName,
        });
    }

    render() {
        this.setVars();
        this.setEventListeners();
    }
};
